import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTableViewFilters } from '../../../contexts/table-view/TableViewFilterContext';
import { Option } from '../../Option';
import FormResponseService from '../../../services/FormResponseService';
import SkeletonLoader from '../../shared/skeleton-loader/SkeletonLoader';
import Checkbox from '../../shared/form-control/Checkbox';
import useInfiniteScroll from '../../../hooks/useInfiniteScroll';
import Loader from '../../shared/Loader';
import { GenericFilterValue as GenericFilterValue } from '../../../models/TableViewFilters';
import { SearchInput } from '../../shared/form-control/SearchInput';
import { InputStyle } from '../../shared/form-control/Input';
import useDebounce from '../../../hooks/useDebounce';
import { FilterProps } from './FilterProps';
import { useTranslation } from 'react-i18next';
import { ApiResponse } from '../../../models/ApiResponse';
import { mouseAndKeyboardCallbackProps } from '../../../utils/ComponentUtils';
import { convertToRichText } from '../../../utils/RichTextUtils';
import HtmlPreview from '../../shared/placeholder/HtmlPreview';

export type GenericFilterProps = {
  loadPage?: (
    selectedTemplateId: string,
    actionId: string,
    searchPhrase: string,
    pageNum: number,
  ) => Promise<ApiResponse<Option<number, string | false>[]>>;
  includeNoData?: boolean;
};

const DefaultLoadPage: GenericFilterProps['loadPage'] = (selectedTemplateId: string, actionId: string, searchPhrase: string, pageNum: number) =>
  FormResponseService.getAnswers(selectedTemplateId, actionId, searchPhrase, pageNum).then((res) => {
    return {
      ...res,
      data: res.data.map((x, i) => ({ id: i, text: x.answer, value: x.answer })),
    };
  });

const GenericFilter: FC<FilterProps & GenericFilterProps> = (props) => {
  const { columnConfig, selectedTemplateId, loadPage: loadPageExternal, includeNoData = true } = props;
  const { setFilters, filters } = useTableViewFilters();
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [options, setOptions] = useState<Option<number, string | false>[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchPhrase, setSearchPhrase] = useState('');
  const debouncedSearchPhrase = useDebounce(searchPhrase, 500);
  const { t } = useTranslation(['table-view', 'common']);

  const noDataArr = useMemo(() => {
    if (includeNoData) {
      return [
        {
          id: -1,
          text: t('filters.no-data'),
          value: false as const,
        },
      ];
    }

    return [];
  }, [includeNoData, t]);

  const loadPage = useCallback(
    (pageNum: number) => {
      setIsLoading(true);
      const loadFn = loadPageExternal ?? DefaultLoadPage;
      return loadFn(selectedTemplateId, columnConfig.value, debouncedSearchPhrase, pageNum).then((res) => {
        setOptions((prev) => (pageNum === 1 ? noDataArr : prev).concat(res.data));
        setCurrentPage(res.pageNumber!);
        setTotalPages(res.totalPages!);
        setIsLoading(false);
      });
    },
    [columnConfig.value, debouncedSearchPhrase, loadPageExternal, noDataArr, selectedTemplateId],
  );

  useEffect(() => {
    setInitialLoading(true);

    loadPage(1).then(() => {
      setInitialLoading(false);
    });
  }, [loadPage]);

  const [lastElementRef] = useInfiniteScroll(currentPage < totalPages ? () => loadPage(currentPage + 1) : null, isLoading);

  const filterValue = useMemo(() => {
    return (filters?.[selectedTemplateId]?.[columnConfig.value]?.filter as GenericFilterValue) ?? [];
  }, [columnConfig.value, filters, selectedTemplateId]);

  const clearAll = () => {
    setFilters(columnConfig, selectedTemplateId, undefined);
  };

  const selectAll = () => {
    setFilters(
      columnConfig,
      selectedTemplateId,
      options.map((x) => x.value as string),
    );
  };

  const isSelected = useCallback(
    (value: string | false) => {
      return filterValue?.includes(value) ?? false;
    },
    [filterValue],
  );

  const selectOption = useCallback(
    (value: string | false, selected: boolean) => {
      const updatedFilter = selected ? filterValue.concat(value) : filterValue.filter((x) => x !== value);
      setFilters(columnConfig, selectedTemplateId, updatedFilter.length > 0 ? updatedFilter : undefined);
    },
    [columnConfig, filterValue, selectedTemplateId, setFilters],
  );

  return (
    <div>
      <div className="-mx-2 my-2">
        <SearchInput style={InputStyle.MINIMAL} value={searchPhrase} onChange={(e) => setSearchPhrase(e.target.value)} />
      </div>
      <div className="text-dpm-14 my-1 flex flex-row justify-between font-medium">
        <span className="cursor-pointer hover:underline" {...mouseAndKeyboardCallbackProps(() => selectAll())} data-cy="select-all">
          {t('common:list.filter.select-all')}
        </span>
        <span className="cursor-pointer hover:underline" {...mouseAndKeyboardCallbackProps(() => clearAll())} data-cy="clear-all">
          {t('common:list.filter.clear-all')}
        </span>
      </div>
      <SkeletonLoader ready={!initialLoading} type="blockRow" rows={3}>
        <div className="-mr-4 h-64 w-72 space-y-4 overflow-y-auto">
          {options.map((x, i) => (
            <div key={x.id} ref={i === options.length - 1 ? lastElementRef : undefined}>
              <Checkbox
                value={isSelected(x.value)}
                onChange={(v) => selectOption(x.value, v)}
                label={
                  <HtmlPreview placeholders={{}} plainText={true}>
                    {convertToRichText(x.text ?? '')?.value ?? ''}
                  </HtmlPreview>
                }
              />
            </div>
          ))}
          {isLoading && (
            <div className="relative h-8">
              <Loader size={6} centered />
            </div>
          )}
          {!isLoading && options.length === 0 && <div className="text-center">{t('filters.no-results')}</div>}
        </div>
      </SkeletonLoader>
    </div>
  );
};

export default GenericFilter;
